import React, {Suspense, lazy, useCallback, useEffect, useRef, useState} from 'react'

import {BranchInfoBar, BranchInfoBarErrorBanner} from '@github-ui/code-view-shared/components/directory/BranchInfoBar'
import {AddFileDropdownButton} from '@github-ui/code-view-shared/components/AddFileDropdownButton'
import {CodeDropdownButton} from '@github-ui/code-dropdown-button'
import type {CodespacesTabProps} from '@github-ui/code-dropdown-button/components/CodespacesTab'
import {buildCodespacesPath, CodespacesTab} from '@github-ui/code-dropdown-button/components/CodespacesTab'
import {CopilotTab} from '@github-ui/code-dropdown-button/components/CopilotTab'
import {LocalTab} from '@github-ui/code-dropdown-button/components/LocalTab'
import {AllShortcutsEnabled} from '@github-ui/code-view-shared/components/AllShortcutsEnabled'
import {CodeViewError} from '@github-ui/code-view-shared/components/CodeViewError'
import {ReposHeaderRefSelector} from '@github-ui/code-view-shared/components/ReposHeaderRefSelector'
import {useFilesPageInfo, useReposAppPayload} from '@github-ui/code-view-shared/contexts/FilesPageInfoContext'
import type {FileOverviewPayload} from '@github-ui/code-view-types'
import {useCurrentRepository} from '@github-ui/current-repository'
import {useCurrentUser} from '@github-ui/current-user'
import {branchesPath, repositoryTreePath, tagsPath, branchAndTagCountPath} from '@github-ui/paths'
import {ErrorBoundary} from '@github-ui/react-core/error-boundary'
import {Link} from '@github-ui/react-core/link'
import {ScreenSize} from '@github-ui/screen-size'
import {useNavigate} from '@github-ui/use-navigate'
import {verifiedFetchJSON} from '@github-ui/verified-fetch'
import {GitBranchIcon, KebabHorizontalIcon, PlusIcon, SearchIcon, TagIcon, UploadIcon} from '@primer/octicons-react'
import {ActionList, ActionMenu, Box, Button, IconButton} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {DirectoryContent} from '@github-ui/code-view-shared/components/directory/DirectoryContent'

import {OverviewFiles} from './OverviewRepoFiles'
import {OverviewHeader} from './header/OverviewHeader'
import BranchRenamePopover from './popovers/BranchRenamePopover'
import ParentBranchRenamePopover from './popovers/ParentBranchRenamePopover'
import {useShortcut} from '@github-ui/code-view-shared/hooks/shortcuts'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {CodeButton} from './CodeButton'

const FileResultsList = lazy(() => import('@github-ui/code-view-shared/components/files-search/FileResultsList'))

export default function OverviewContent({payload}: {payload: FileOverviewPayload}) {
  const {helpUrl} = useReposAppPayload()
  const useSharedCodeDropdownButton = useFeatureFlag('overview_shared_code_dropdown_button')
  const copilotEnabled = useFeatureFlag('copilot_workspace')

  const repo = useCurrentRepository()
  const currentUser = useCurrentUser()
  const {refInfo, path} = useFilesPageInfo()
  const {findFileWorkerPath} = useReposAppPayload()
  const navigate = useNavigate()
  const {findFilesShortcut} = useShortcut()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [branchAndTagCount, setBranchAndTagCount] = useState<{branches: number; tags: number} | null>(null)

  const overview = payload.overview
  const codeButton = overview.codeButton
  const local = {...codeButton.local, helpUrl}
  const copilot = {repoOwner: repo.ownerLogin, repoName: repo.name, refName: refInfo.name}
  const codespaces = {
    hasAccessToCodespaces: codeButton.hasAccessToCodespaces,
    repoPolicyInfo: codeButton.repoPolicyInfo,
    contactPath: codeButton.contactPath,
    currentUserIsEnterpriseManaged: codeButton.currentUserIsEnterpriseManaged,
    enterpriseManagedBusinessName: codeButton.enterpriseManagedBusinessName,
    newCodespacePath: codeButton.newCodespacePath,
    codespacesPath: buildCodespacesPath(repo.id, refInfo.name),
    isLoggedIn: !!currentUser,
  } as CodespacesTabProps

  useEffect(() => {
    const fetchBranchAndTagCount = async () => {
      const response = await verifiedFetchJSON(branchAndTagCountPath(repo), {method: 'get'})
      if (response.ok) {
        const data = await response.json()
        setBranchAndTagCount(data)
      }
    }
    if (overview) {
      fetchBranchAndTagCount()
    }
  }, [overview, repo])

  const onFindFilesShortcut = React.useCallback(() => {
    if (window.innerWidth < ScreenSize.xlarge) {
      navigate(`${window.location.pathname}?search=1`)
    } else {
      inputRef.current?.focus()
    }
  }, [navigate])

  const navigateToSearch = useCallback(() => {
    navigate(`${window.location.pathname}?search=1`)
  }, [navigate])

  return (
    <>
      {payload.error ? (
        <CodeViewError {...payload.error} />
      ) : (
        <Box sx={{my: 3}}>
          <OverviewHeader payload={payload} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexGrow: 1,
              pb: 3,
              pt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                '@media screen and (max-width: 320px)': {flexGrow: 1},
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  '@media screen and (max-width: 380px)': {
                    '.ref-selector-button-text-container': {
                      maxWidth: '80px',
                    },
                  },
                  '@media screen and (max-width: 320px)': {
                    flexGrow: 1,
                    '.overview-ref-selector': {
                      width: '100%',
                      '> span': {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        '> span[data-component="text"]': {flexGrow: 1},
                      },
                    },
                  },
                }}
              >
                <ReposHeaderRefSelector buttonClassName="overview-ref-selector width-full" allowResizing />
                {overview.popovers.rename ? (
                  <BranchRenamePopover rename={overview.popovers.rename} />
                ) : overview.popovers.renamedParentRepo ? (
                  <ParentBranchRenamePopover
                    branchName={overview.popovers.renamedParentRepo.branchName}
                    nameWithOwner={overview.popovers.renamedParentRepo.nameWithOwner}
                  />
                ) : null}
              </Box>

              <Box sx={{display: 'flex', '@media screen and (max-width: 1079px)': {display: 'none'}}}>
                <Button
                  as="a"
                  leadingVisual={GitBranchIcon}
                  variant="invisible"
                  href={branchesPath({repo})}
                  sx={{color: 'fg.muted', px: 1, 'span[data-component="leadingVisual"]': {mr: '4px !important'}}}
                >
                  {branchAndTagCount ? (
                    <span>
                      <strong className="color-fg-default">{branchAndTagCount.branches} </strong>
                      {branchAndTagCount.branches === 1 ? 'Branch' : 'Branches'}
                    </span>
                  ) : (
                    'Branches'
                  )}
                </Button>

                <Button
                  as="a"
                  leadingVisual={TagIcon}
                  variant="invisible"
                  href={tagsPath({repo})}
                  sx={{color: 'fg.muted', px: 1, 'span[data-component="leadingVisual"]': {mr: '4px !important'}}}
                >
                  {branchAndTagCount ? (
                    <span>
                      <strong className="color-fg-default">{branchAndTagCount.tags} </strong>
                      {branchAndTagCount.tags === 1 ? 'Tag' : 'Tags'}
                    </span>
                  ) : (
                    'Tags'
                  )}
                </Button>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  '@media screen and (min-width: 1080px)': {display: 'none'},
                  '@media screen and (max-width: 543px)': {display: 'none'},
                }}
              >
                <Button
                  as="a"
                  aria-label="Go to Branches page"
                  icon={GitBranchIcon}
                  variant="invisible"
                  href={branchesPath({repo})}
                  sx={{color: 'fg.muted'}}
                />
                <Button
                  as="a"
                  aria-label="Go to Tags page"
                  icon={TagIcon}
                  variant="invisible"
                  href={tagsPath({repo})}
                  sx={{color: 'fg.muted'}}
                />
              </Box>
            </Box>
            <Box sx={{display: 'flex', pl: 2, gap: 2}}>
              <Box sx={{display: 'flex', gap: 2, '@media screen and (max-width: 543px)': {display: 'none'}}}>
                <AllShortcutsEnabled>
                  <button hidden data-hotkey={findFilesShortcut.hotkey} onClick={onFindFilesShortcut} />
                </AllShortcutsEnabled>
                <Box sx={{display: 'flex', '@media screen and (max-width: 1011px)': {display: 'none'}}}>
                  <Suspense fallback={null}>
                    <FileResultsList
                      commitOid={refInfo.currentOid}
                      findFileWorkerPath={findFileWorkerPath}
                      sx={{m: 0}}
                      searchBoxRef={inputRef}
                    />
                  </Suspense>
                </Box>
                <Box sx={{display: 'flex', '@media screen and (min-width: 1012px)': {display: 'none'}}}>
                  <Button onClick={navigateToSearch}>Go to file</Button>
                </Box>
                <div className="react-directory-add-file-icon">
                  <AddFileDropdownButton useIcon />
                </div>
                <div className="react-directory-remove-file-icon">
                  <AddFileDropdownButton useIcon={false} />
                </div>
              </Box>
              {useSharedCodeDropdownButton ? (
                <CodeDropdownButton
                  primary={!overview.templateButton}
                  showCodespacesTab={codeButton.codespacesEnabled}
                  showCopilotTab={copilotEnabled}
                  isEnterprise={codeButton.isEnterprise}
                  localTab={<LocalTab {...local} />}
                  copilotTab={<CopilotTab {...copilot} />}
                  codespacesTab={<CodespacesTab {...codespaces} />}
                />
              ) : (
                <CodeButton
                  repoId={repo.id}
                  repoOwner={repo.ownerLogin}
                  repoName={repo.name}
                  refName={refInfo.name}
                  payload={overview}
                  isLoggedIn={!!currentUser}
                  primary={!overview.templateButton}
                />
              )}
              <Box sx={{display: 'flex', '@media screen and (min-width: 544px)': {display: 'none'}}}>
                <ActionMenu>
                  <ActionMenu.Anchor>
                    {/* eslint-disable-next-line primer-react/a11y-remove-disable-tooltip */}
                    <IconButton unsafeDisableTooltip icon={KebabHorizontalIcon} aria-label="Open more actions menu" />
                  </ActionMenu.Anchor>

                  <ActionMenu.Overlay>
                    <ActionList>
                      <ActionList.LinkItem href={branchesPath({repo})}>
                        <ActionList.LeadingVisual>
                          <Octicon icon={GitBranchIcon} />
                        </ActionList.LeadingVisual>
                        Branches
                      </ActionList.LinkItem>
                      <ActionList.LinkItem href={tagsPath({repo})}>
                        <ActionList.LeadingVisual>
                          <Octicon icon={TagIcon} />
                        </ActionList.LeadingVisual>
                        Tags
                      </ActionList.LinkItem>
                      <ActionList.Divider />
                      <ActionList.Item onSelect={navigateToSearch}>
                        <ActionList.LeadingVisual>
                          <Octicon icon={SearchIcon} />
                        </ActionList.LeadingVisual>
                        Go to file
                      </ActionList.Item>
                      <ActionList.LinkItem
                        as={Link}
                        to={repositoryTreePath({repo, path, commitish: refInfo.name, action: 'new'})}
                      >
                        <ActionList.LeadingVisual>
                          <Octicon icon={PlusIcon} />
                        </ActionList.LeadingVisual>
                        Create new file
                      </ActionList.LinkItem>
                      <ActionList.LinkItem
                        href={repositoryTreePath({repo, path, commitish: refInfo.name, action: 'upload'})}
                      >
                        <ActionList.LeadingVisual>
                          <Octicon icon={UploadIcon} />
                        </ActionList.LeadingVisual>
                        Upload file
                      </ActionList.LinkItem>
                    </ActionList>
                  </ActionMenu.Overlay>
                </ActionMenu>
              </Box>
            </Box>
          </Box>
          {payload.tree.showBranchInfobar && (
            <ErrorBoundary fallback={<BranchInfoBarErrorBanner />}>
              <BranchInfoBar />
            </ErrorBoundary>
          )}
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
            <DirectoryContent overview={payload.overview} />
            <OverviewFiles
              initialFiles={overview.overviewFiles}
              shouldRecommendReadme={overview.banners.shouldRecommendReadme}
              isPersonalRepo={overview.banners.isPersonalRepo}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

try{ FileResultsList.displayName ||= 'FileResultsList' } catch {}
try{ OverviewContent.displayName ||= 'OverviewContent' } catch {}